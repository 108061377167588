import React,{useState} from 'react'
import goleila_logo from "../Assets/goleila_logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Boxform from './Boxform';

function Footer() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const [showBoxform, setShowBoxform] = useState(false);

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={goleila_logo} alt="" />
        </div>
        <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span onClick={scrollToTop}>Scopri</span>
          <span onClick={()=>setShowBoxform(true)}>Prenota una Demo</span>
         
        </div>
        <div className="footer-section-columns">
          <span>+39 347 3985656</span>
          <span>info@leila.com</span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
      {showBoxform && (
        <>
          <div className="background-overlay" onClick={() => setShowBoxform(false)}></div>
          <div className="boxform-overlay">
            <Boxform />
          </div>
        </>
      )}
    </div>
  )
}

export default Footer