import React, {useState} from "react";
import Navbar from "./Navbar";
import Demo from "./Demo";
import Boxform from "./Boxform";
import SoundwaveNew from "./SoundwaveNew";



function Home() {

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    }
    
    
    const [showBoxform, setShowBoxform] = useState(false);


  return (

    <div id="scopri" className="home-container">
      <Navbar />
      <div className="master-container">
      <div className="home-banner-container">

        <div className="home-text-section">
          <div className="w3-animate-left">
            <h1 className="king-heading">
              Bye Bye cold calling. Welcome AI
            </h1>
          </div>
          <p className="primary-text">
            Qualifichiamo e convertiamo le tue lead tramite il nostro call center virtuale  
          </p>
          <button className="primary-button" onClick={() => setShowBoxform(true)}>Unisciti alla lista d'attesa</button>
        </div>
        <div className="home-image-section">
          <div >
            <Demo />
          </div>
        </div>

      </div>
      {showBoxform && (
        <>
          <div className="background-overlay" onClick={() => setShowBoxform(false)}></div>
          <div className="boxform-overlay">
            <Boxform />
          </div>
        </>
      )}
    </div></div>
  );
 
}

export default Home

