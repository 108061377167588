
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Work from './components/Work';
import Footer from './components/Footer';
import Form from './components/Form';
import Perchi from './components/Perchi';


function App() {
  return (
    <div className="App">
      <Home/>
      <Perchi/>
      
      
      <Work/>
      <Form/>
      
      <Footer/>
    
    </div>
  );
}

export default App;
