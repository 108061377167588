import React from 'react'
//import AboutBackground from "../Assets/about-background.png";
//import AboutBackgroundImage from "../Assets/about-background-image.png";

import CCagent from "../Assets/callcenter_agent.png"
import sfondoabout from "../Assets/sfondo_about.png"
//<img src={} className='about-image' alt="" />
function About() {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        
      </div>
      <div className="about-section-image-container">
        <img src={CCagent} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About Leila</p>
        <h1 className="primary-heading">
          Disrupting the call center industry
        </h1>
        <p className="primary-text">
          Leila è il tuo agente per le vendite completamente autonomo, è in grado fornire le informazioni al cliente su un determinato prodotto e convincerlo a comprarlo
        </p>
        <p className="primary-text">
          Finalmente potrai raggiungere tutti i tuoi potenziali clienti personalmente, senza l'uso di strumenti poco persuasivi quali mail o sms
          
        </p>
       
      </div>
    </div>
  )
}

export default About