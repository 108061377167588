import React from 'react'
import schermoristo from "../Assets/schermoristo.png"
import schermo_assi from "../Assets/schermo_assi.png"
import google_logo from "../Assets/google_logo2.png"
import salesforce_logo from "../Assets/salesforce_logo.png"
import whatsapp_logo from "../Assets/whatsapp_logo.png"


function Perchi() {
  const integrazioni = [
    { image: google_logo },
    { image: whatsapp_logo },
    { image: salesforce_logo }
  ]


  return (

    <div className='master-container'>
      <div className="about-section-container" id='piulead'>
      <div className="about-section-text-container" >
          <p className="primary-subheading">Hai un e-commerce o vendi prodotti online?</p>
          <h1 className="secondary-heading">
            Hai tante lead ma non sai come convertirle? lascia fare a GoLeila
          </h1>
          <p className="primary-text">
            Tramite l'intelligenza artificiale Leila chiama le tue lead, instaura un rapporto empatico, ascolta le esigenze e propone la soluzione più adeguata
          </p>
          <p className="primary-text">
            Leila è in grado di interagire tramite con i tuoi sistemi esistenti in modo tale che da parte tua non sia richiesta nessuna azione aggiuntiva
          </p>
          <div className='integration-container'>
            {integrazioni.map((data) => (
              <img className='integration_image' src={data.image} />
            ))}
          </div>

        </div>
        <div className="about-section-image-container">
          <img src={schermo_assi} alt="" />
        </div>

      </div>

      <div className="about-section-container-inverted" id='receptionist'>

        
      <div className="about-section-image-container">
          <img src={schermoristo} alt="" />
        </div>
        <div className="about-section-text-container">
          <p className="primary-subheading">per ristoratori, parrucchieri, hotel..</p>
          <h1 className="secondary-heading">
            GoLeila supporta la gestione dei clienti nella tua attività
          </h1>
          <p className="primary-text">
            Tramite l'integrazione con i sistemi di prenotazione più comuni non dovrai preoccuparti più di occupare il tuo prezioso tempo rispondendo alle chiamate
          </p>
          <p className="primary-text">
            Basterà meno di un'ora di tempo per collegare Leila ai tuoi sistemi esistenti
          </p>

        </div>



              </div>
    </div>
  )
}

export default Perchi