import React,{useState} from 'react'

function Boxform() {

    const [formvalue, setFormvalue] =useState({name:"", mail:""})

    const [emailError, setEmailError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    
    const handleinput =(e) =>{
      const {name, value}=e.target;
      setFormvalue({...formvalue,[name]:value})
      console.log(formvalue)
    }
    
    function validateEmail(email) {
      const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailPattern.test(email);
    }
    
    
    //https://leila-backend.onrender.com
    //http://localhost:5000
    
    const handleFormsubmit = async(e) => {
      e.preventDefault();
      
    
        if (!validateEmail(formvalue.mail)) {
          setEmailError('Email not valid');
          return;
        } else {
          setEmailError('');
        }
      console.log(formvalue)
      const response = await fetch('https://leila-backend.onrender.com/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({formvalue}),
        });
    
        if (response.ok) {
          
          setFormvalue({name:"", mail:""})
          setIsSubmitted(true); // Set isSubmitted to true
        } else {
          alert('Error saving data');
        }
      };
    


  return (
    <div className="contact-form-container">
    <form className="contact-form" onSubmit={handleFormsubmit}>
      {!isSubmitted && (
        <>
          <input
            type="text"
            name="name"
            value={formvalue.name}
            onChange={handleinput}
            placeholder="yourname"
          />

          <input
            type="text"
            spellCheck="false"
            name="mail"
            value={formvalue.mail}
            onChange={handleinput}
            placeholder="yourmail@gmail.com"
          />
        </>
      )}
      <button className={`secondary-button ${isSubmitted ? 'button-light-green' : ''}`}>
        {isSubmitted ? 'Richiesta inviata' : "unisciti alla lista d'attesa"}
      </button>
      {emailError && <p>{emailError}</p>}
    </form>


  </div>
  )
}

export default Boxform