import React, { useState, useEffect, useRef }from 'react'
import play from "../Assets/play.png"
import _demo_audio from "../Assets/2904_demo_audio.mp3";
import WaveSurfer from 'wavesurfer.js';
import stop from "../Assets/stop.png"



function Demo() {
  const [playing, setPlaying] = useState(false);
  const wavesurferRef = useRef(null);
  const waveFormRef = useRef(null);

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      container: waveFormRef.current,
      waveColor: '#D9DCFF',
      progressColor: '#4353FF',
      cursorColor: '#4353FF',
      barWidth: 6,
      barRadius: 4,
      cursorWidth: 1,
      height: 200,
      barGap: 6,
    });
    wavesurferRef.current.load(_demo_audio);
  
    wavesurferRef.current.on('finish', () => {
      setPlaying(false);
    });
  
    return () => {
      wavesurferRef.current.destroy(); // Aggiungi questa riga
    };
  }, []);

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurferRef.current.playPause();

  };


  return (
    <div className="demo-container">
    <h1 className="color-seconday-header"></h1>
    <div className="chat-container" style={{ position: 'relative' }}>
      
      <div ref={waveFormRef} />
      <div className="play-button-container">
        <img src={playing ? stop : play} id="play_button" onClick={handlePlayPause} />
      </div>
    </div>
  </div>
  )
}

export default Demo