import React, {useState,useEffect} from 'react'
import goleila_logo from "../Assets/goleila_logo.png"
import Boxform from './Boxform';


import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InsightsIcon from '@mui/icons-material/Insights';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import CommentRoundedIcon from "@mui/icons-material/CommentRounded";



function Navbar() {

    const [openMenu,setOpenMenu]=useState(false)
    const [showBoxform, setShowBoxform] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };


    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
    });

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 95, // You can subtract a value to account for the height of the navbar, if needed
          behavior: 'smooth',
        });
      }
      setOpenMenu(false);
    };

    const menuOptions = [
        {
          text: "Scopri il prodotto",
          icon: <HomeIcon />,
          sectionId: "scopri"
        },
        
        {
          text: "Convertire più lead",
          icon: < InsightsIcon />,
          sectionId: "piulead"
        },
        {
          text: "AI Receptionist",
          icon: <ContactPhoneIcon />,
          sectionId: "receptionist"
        },
       
        {
          text: "Come funziona",
          icon: <AutoFixHighIcon />,
          sectionId: "come-funziona"
        },
       
      
    ]

   


  return (
    <nav className={scrolled ? "scrolled" : ""}>
    <div className="nav-logo-container">
      <img className='navbar-logo' src={goleila_logo} alt=""/>
    </div>
    <div className="navbar-links-container">
      
      <button className='desktop-menu-button' onClick={()=>scrollToSection('piulead')}>Sales agent</button>
      <button className='desktop-menu-button' onClick={()=>scrollToSection("receptionist")}>AI receptionist</button>
      
      <button className='desktop-menu-button' onClick={()=>scrollToSection("come-funziona")}>Come funziona</button>
    
      <button className="primary-button" onClick={() => setShowBoxform(true)}>Voglio provarlo</button>
    </div>
    <div className="navbar-menu-container">
      <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
    </div>
    <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <a className='mobile-menu-button' key={item.text} disablePadding  onClick={() => scrollToSection(item.sectionId)}>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </a>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>

      {showBoxform && (
        <>
          <div className="background-overlay" onClick={() => setShowBoxform(false)}></div>
          <div className="boxform-overlay">
            <Boxform />
          </div>
        </>
      )}
  </nav>
  
  )
}

export default Navbar