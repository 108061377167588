import React from 'react'
import robot from "../Assets/robot.png"
import teacher from "../Assets/teacher.png"
import soldi from "../Assets/soldi.png"
function Work() {
    const workInfoData = [
        {
          image: teacher,
          title: "Istruisci",
          text: "Forniscici le informazioni sul tuo prodotto e quale è il tuo obiettivo: fissare un appuntamento, convertire lead all'acquisto di un prodotto...",
        },
        {
          image: robot,
          title: "Lascia fare a GoLeila",
          text: "GoLeila in autonomia chiamera la lista di contatti da te fornita e li convincera a raggiungere l'obiettivo prefissato, nessuna interazione umana da parte tua sarà necessaria",
        },
        {
          image:soldi,
          title: "Aumenta le tue vendite",
          text: "GoLeila è completamente flessibile e scalabile. Questo vuol dire niente più lead perse nei momenti di alta stagione per mancanza di risorse umane",
        },
      ];
      return (
        <div className="work-section-wrapper" id="come-funziona">
          <div className="work-section-top">
            <p className="primary-subheading">Installazione veloce e facile</p>
            <h1 className="primary-heading">Come funziona</h1>
            <p className="primary-text">
              Ti basterà meno di un'ora per lanciare il tuo agente addetto alle vendite autonomo
            </p>
          </div>
          <div className="work-section-bottom">
            {workInfoData.map((data) => (
              <div className="work-section-info" key={data.title}>
                <div className="info-boxes-img-container">
                  <img class="work-image" src={data.image} alt="" />
                </div>
                <h2>{data.title}</h2>
                <p>{data.text}</p>
              </div>
            ))}
          </div>
        </div>
      );
}

export default Work