import React,  {useState} from 'react'
import Boxform from './Boxform'
function Form() {




  return (
    <div className="contact-page-wrapper" id='Form'>
      <h1 className="primary-heading">Unisciti alla lista d'attesa</h1>
      <h2 className="seconday-heading">ti terremo aggiornato</h2>
      <Boxform/>
    </div>
  )
}

export default Form